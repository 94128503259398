var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
!function (t, e) {
  exports = e();
}(exports, function () {
  "use strict";

  function t(t, i) {
    t.prototype = Object.create(i.prototype), t.prototype.constructor = t, e(t, i);
  }
  function e(t, i) {
    return e = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function (t, e) {
      return t.__proto__ = e, t;
    }, e(t, i);
  }
  function i(t) {
    if (void 0 === t) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return t;
  }
  function s(t) {
    throw new TypeError("\"" + t + "\" is read-only");
  }
  function a(t, e) {
    (null == e || e > t.length) && (e = t.length);
    for (var i = 0, s = new Array(e); i < e; i++) s[i] = t[i];
    return s;
  }
  function n(t, e) {
    var i = "undefined" != typeof Symbol && t[Symbol.iterator] || t["@@iterator"];
    if (i) return (i = i.call(t)).next.bind(i);
    if (Array.isArray(t) || (i = function (t, e) {
      if (t) {
        if ("string" == typeof t) return a(t, e);
        var i = Object.prototype.toString.call(t).slice(8, -1);
        return "Object" === i && t.constructor && (i = t.constructor.name), "Map" === i || "Set" === i ? Array.from(t) : "Arguments" === i || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(i) ? a(t, e) : void 0;
      }
    }(t)) || e && t && "number" == typeof t.length) {
      i && (t = i);
      var s = 0;
      return function () {
        return s >= t.length ? {
          done: !0
        } : {
          done: !1,
          value: t[s++]
        };
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  Element.prototype.matches || (Element.prototype.matches = Element.prototype.matchesSelector || Element.prototype.mozMatchesSelector || Element.prototype.msMatchesSelector || Element.prototype.oMatchesSelector || Element.prototype.webkitMatchesSelector || function (t) {
    for (var e = ((this || _global).document || (this || _global).ownerDocument).querySelectorAll(t), i = e.length; s("i") >= 0 && e.item(i) !== (this || _global););
    return i > -1;
  }), Object.assign || Object.defineProperty(Object, "assign", {
    enumerable: !1,
    configurable: !0,
    writable: !0,
    value: function (t) {
      if (null == t) throw new TypeError("Cannot convert first argument to object");
      for (var e = Object(t), i = 1; i < arguments.length; i++) {
        var s = arguments[i];
        if (null != s) {
          s = Object(s);
          for (var a = Object.keys(Object(s)), n = 0, r = a.length; n < r; n++) {
            var o = a[n],
              h = Object.getOwnPropertyDescriptor(s, o);
            void 0 !== h && h.enumerable && (e[o] = s[o]);
          }
        }
      }
      return e;
    }
  });
  var r = function (t) {
    return function (t) {
      return !!t && "object" == typeof t;
    }(t) && !function (t) {
      var e = Object.prototype.toString.call(t);
      return "[object RegExp]" === e || "[object Date]" === e || function (t) {
        return t instanceof Node;
      }(t) || function (t) {
        return t.$$typeof === o;
      }(t);
    }(t);
  };
  var o = "function" == typeof Symbol && Symbol.for ? Symbol.for("react.element") : 60103;
  function h(t, e) {
    return !1 !== e.clone && e.isMergeableObject(t) ? d((i = t, Array.isArray(i) ? [] : {}), t, e) : t;
    var i;
  }
  function l(t, e, i) {
    return t.concat(e).map(function (t) {
      return h(t, i);
    });
  }
  function c(t) {
    return Object.keys(t).concat(function (t) {
      return Object.getOwnPropertySymbols ? Object.getOwnPropertySymbols(t).filter(function (e) {
        return t.propertyIsEnumerable(e);
      }) : [];
    }(t));
  }
  function u(t, e) {
    try {
      return e in t;
    } catch (t) {
      return !1;
    }
  }
  function p(t, e, i) {
    var s = {};
    return i.isMergeableObject(t) && c(t).forEach(function (e) {
      s[e] = h(t[e], i);
    }), c(e).forEach(function (a) {
      (function (t, e) {
        return u(t, e) && !(Object.hasOwnProperty.call(t, e) && Object.propertyIsEnumerable.call(t, e));
      })(t, a) || (u(t, a) && i.isMergeableObject(e[a]) ? s[a] = function (t, e) {
        if (!e.customMerge) return d;
        var i = e.customMerge(t);
        return "function" == typeof i ? i : d;
      }(a, i)(t[a], e[a], i) : s[a] = h(e[a], i));
    }), s;
  }
  var d = function (t, e, i) {
      (i = i || {}).arrayMerge = i.arrayMerge || l, i.isMergeableObject = i.isMergeableObject || r, i.cloneUnlessOtherwiseSpecified = h;
      var s = Array.isArray(e);
      return s === Array.isArray(t) ? s ? i.arrayMerge(t, e, i) : p(t, e, i) : h(e, i);
    },
    f = function (t) {
      return "object" == typeof t && void 0 !== t.nodeType ? t : "string" == typeof t ? document.querySelector(t) : null;
    },
    m = function (t, e, i, s) {
      void 0 === s && (s = !1);
      var a = document.createElement(t);
      return i && (a[s ? "innerHTML" : "textContent"] = i), e && (a.className = e), a;
    },
    g = function (t) {
      t.parentNode.removeChild(t);
    },
    v = function (t, e, i) {
      return void 0 === i && (i = !1), i ? d(t, e) : Object.assign(t, e);
    },
    _ = function (t, e) {
      return t.toLowerCase() + ":to:" + e.toLowerCase();
    },
    y = function (t, e) {
      Object.assign(t.prototype, e);
    },
    b = {},
    w = 1,
    S = {
      on: function (t, e, i, s) {
        void 0 === s && (s = {});
        var a = "jvm:" + e + "::" + w++;
        b[a] = {
          selector: t,
          handler: i
        }, i._uid = a, t.addEventListener(e, i, s);
      },
      delegate: function (t, e, i, s) {
        (e = e.split(" ")).forEach(function (e) {
          S.on(t, e, function (t) {
            var e = t.target;
            e.matches(i) && s.call(e, t);
          });
        });
      },
      off: function (t, e, i) {
        var s = e.split(":")[1];
        t.removeEventListener(s, i), delete b[i._uid];
      },
      flush: function () {
        Object.keys(b).forEach(function (t) {
          S.off(b[t].selector, t, b[t].handler);
        });
      },
      getEventRegistry: function () {
        return b;
      }
    };
  var k = {
      onLoaded: "map:loaded",
      onViewportChange: "viewport:changed",
      onRegionClick: "region:clicked",
      onMarkerClick: "marker:clicked",
      onRegionSelected: "region:selected",
      onMarkerSelected: "marker:selected",
      onRegionTooltipShow: "region.tooltip:show",
      onMarkerTooltipShow: "marker.tooltip:show",
      onDestroyed: "map:destroyed"
    },
    M = function (t, e, i) {
      var s = f(e),
        a = -1 === s.getAttribute("class").indexOf("jvm-region") ? "marker" : "region",
        n = "region" === a,
        r = n ? s.getAttribute("data-code") : s.getAttribute("data-index"),
        o = n ? k.onRegionSelected : k.onMarkerSelected;
      return i && (o = n ? k.onRegionTooltipShow : k.onMarkerTooltipShow), {
        type: a,
        code: r,
        event: o,
        element: n ? t.regions[r].element : t._markers[r].element,
        tooltipText: n ? t._mapData.paths[r].name || "" : t._markers[r].config.name || ""
      };
    };
  var x = function () {
      function t() {}
      return t.prototype.dispose = function () {
        (this || _global)._tooltip ? g((this || _global)._tooltip) : (this || _global).shape.remove();
        for (var t, e = n(Object.getOwnPropertyNames(this || _global)); !(t = e()).done;) {
          (this || _global)[t.value] = null;
        }
      }, t;
    }(),
    j = {
      getLabelText: function (t, e) {
        if (e) {
          if ("function" == typeof e.render) {
            var i = [];
            return (this || _global).config && (this || _global).config.marker && i.push((this || _global).config.marker), i.push(t), e.render.apply(this || _global, i);
          }
          return t;
        }
      },
      getLabelOffsets: function (t, e) {
        return "function" == typeof e.offsets ? e.offsets(t) : Array.isArray(e.offsets) ? e.offsets[t] : [0, 0];
      },
      setStyle: function (t, e) {
        (this || _global).shape.setStyle(t, e);
      },
      remove: function () {
        (this || _global).shape.remove(), (this || _global).label && (this || _global).label.remove();
      },
      hover: function (t) {
        this._setStatus("isHovered", t);
      },
      select: function (t) {
        this._setStatus("isSelected", t);
      },
      _setStatus: function (t, e) {
        (this || _global).shape[t] = e, (this || _global).shape.updateStyle(), (this || _global)[t] = e, (this || _global).label && ((this || _global).label[t] = e, (this || _global).label.updateStyle());
      }
    },
    C = function (e) {
      function i(t) {
        var i,
          s = t.map,
          a = t.code,
          n = t.path,
          r = t.style,
          o = t.label,
          h = t.labelStyle,
          l = t.labelsGroup;
        (i = e.call(this || _global) || this || _global)._map = s, i.shape = i._createRegion(n, a, r);
        var c = i.getLabelText(a, o);
        if (o && c) {
          var u = i.shape.getBBox(),
            p = i.getLabelOffsets(a, o);
          i.labelX = u.x + u.width / 2 + p[0], i.labelY = u.y + u.height / 2 + p[1], i.label = i._map.canvas.createText({
            text: c,
            textAnchor: "middle",
            alignmentBaseline: "central",
            dataCode: a,
            x: i.labelX,
            y: i.labelY
          }, h, l), i.label.addClass("jvm-region jvm-element");
        }
        return i;
      }
      t(i, e);
      var s = i.prototype;
      return s._createRegion = function (t, e, i) {
        return (t = (this || _global)._map.canvas.createPath({
          d: t,
          dataCode: e
        }, i)).addClass("jvm-region jvm-element"), t;
      }, s.updateLabelPosition = function () {
        (this || _global).label && (this || _global).label.set({
          x: (this || _global).labelX * (this || _global)._map.scale + (this || _global)._map.transX * (this || _global)._map.scale,
          y: (this || _global).labelY * (this || _global)._map.scale + (this || _global)._map.transY * (this || _global)._map.scale
        });
      }, i;
    }(x);
  y(C, j);
  var L = function (e) {
    function i(t) {
      var i,
        s = t.index,
        a = t.map,
        n = t.style,
        r = t.x1,
        o = t.y1,
        h = t.x2,
        l = t.y2,
        c = t.group,
        u = t.config;
      return (i = e.call(this || _global) || this || _global).config = u, i.shape = a.canvas.createLine({
        x1: r,
        y1: o,
        x2: h,
        y2: l,
        dataIndex: s
      }, n, c), i.shape.addClass("jvm-line"), i;
    }
    return t(i, e), i.prototype.setStyle = function (t, e) {
      (this || _global).shape.setStyle(t, e);
    }, i;
  }(x);
  var O = function (e) {
    function i(t) {
      var i,
        s = t.index,
        a = t.style,
        n = t.label,
        r = t.cx,
        o = t.cy,
        h = t.map,
        l = t.group;
      return (i = e.call(this || _global) || this || _global)._map = h, i._isImage = !!a.initial.image, i.config = arguments[0], i.shape = h.canvas[i._isImage ? "createImage" : "createCircle"]({
        dataIndex: s,
        cx: r,
        cy: o
      }, a, l), i.shape.addClass("jvm-marker jvm-element"), i._isImage && i.updateLabelPosition(), n && i._createLabel(i.config), i;
    }
    t(i, e);
    var s = i.prototype;
    return s.updateLabelPosition = function () {
      (this || _global).label && (this || _global).label.set({
        x: (this || _global)._labelX * (this || _global)._map.scale + (this || _global)._offsets[0] + (this || _global)._map.transX * (this || _global)._map.scale + 5 + ((this || _global)._isImage ? ((this || _global).shape.width || 0) / 2 : (this || _global).shape.node.r.baseVal.value),
        y: (this || _global)._labelY * (this || _global)._map.scale + (this || _global)._map.transY * (this || _global)._map.scale + (this || _global)._offsets[1]
      });
    }, s._createLabel = function (t) {
      var e = t.index,
        i = t.map,
        s = t.label,
        a = t.labelsGroup,
        n = t.cx,
        r = t.cy,
        o = t.marker,
        h = t.isRecentlyCreated,
        l = this.getLabelText(e, s);
      (this || _global)._labelX = n / i.scale - i.transX, (this || _global)._labelY = r / i.scale - i.transY, (this || _global)._offsets = h && o.offsets ? o.offsets : this.getLabelOffsets(e, s), (this || _global).label = i.canvas.createText({
        text: l,
        dataIndex: e,
        x: (this || _global)._labelX,
        y: (this || _global)._labelY,
        dy: "0.6ex"
      }, i.params.markerLabelStyle, a), (this || _global).label.addClass("jvm-marker jvm-element"), h && this.updateLabelPosition();
    }, i;
  }(x);
  y(O, j);
  var A = function () {
      function t(t) {
        void 0 === t && (t = {}), (this || _global)._options = t, (this || _global)._map = (this || _global)._options.map, (this || _global)._series = (this || _global)._options.series, (this || _global)._body = m("div", "jvm-legend"), (this || _global)._options.cssClass && (this || _global)._body.setAttribute("class", (this || _global)._options.cssClass), t.vertical ? (this || _global)._map.legendVertical.appendChild((this || _global)._body) : (this || _global)._map.legendHorizontal.appendChild((this || _global)._body), this.render();
      }
      return t.prototype.render = function () {
        var t,
          e = (this || _global)._series.scale.getTicks(),
          i = m("div", "jvm-legend-inner");
        if ((this || _global)._body.innderHTML = "", (this || _global)._options.title) {
          var s = m("div", "jvm-legend-title", (this || _global)._options.title);
          (this || _global)._body.appendChild(s);
        }
        (this || _global)._body.appendChild(i);
        for (var a = 0; a < e.length; a++) {
          var n = m("div", "jvm-legend-tick"),
            r = m("div", "jvm-legend-tick-sample");
          switch ((this || _global)._series.config.attribute) {
            case "fill":
              t = e[a].value, /\.(jpg|gif|png)$/.test(t) ? r.style.background = "url(" + e[a].value + ")" : r.style.background = e[a].value;
              break;
            case "stroke":
              r.style.background = e[a].value;
              break;
            case "image":
              r.style.background = "url(" + ("object" == typeof e[a].value ? e[a].value.url : e[a].value) + ") no-repeat center center", r.style.backgroundSize = "cover";
          }
          n.appendChild(r);
          var o = e[a].label;
          (this || _global)._options.labelRender && (o = (this || _global)._options.labelRender(o));
          var h = m("div", "jvm-legend-tick-text", o);
          n.appendChild(h), i.appendChild(n);
        }
      }, t;
    }(),
    X = function () {
      function t(t) {
        (this || _global)._scale = t;
      }
      var e = t.prototype;
      return e.getValue = function (t) {
        return (this || _global)._scale[t];
      }, e.getTicks = function () {
        var t = [];
        for (var e in (this || _global)._scale) t.push({
          label: e,
          value: (this || _global)._scale[e]
        });
        return t;
      }, t;
    }(),
    Y = function () {
      function t(t, e, i) {
        void 0 === t && (t = {}), (this || _global)._map = i, (this || _global)._elements = e, (this || _global)._values = t.values || {}, (this || _global).config = t, (this || _global).config.attribute = t.attribute || "fill", t.attributes && this.setAttributes(t.attributes), "object" == typeof t.scale && ((this || _global).scale = new X(t.scale)), (this || _global).config.legend && ((this || _global).legend = new A(v({
          map: (this || _global)._map,
          series: this || _global
        }, (this || _global).config.legend))), this.setValues((this || _global)._values);
      }
      var e = t.prototype;
      return e.setValues = function (t) {
        var e = {};
        for (var i in t) t[i] && (e[i] = (this || _global).scale.getValue(t[i]));
        this.setAttributes(e);
      }, e.setAttributes = function (t) {
        for (var e in t) (this || _global)._elements[e] && (this || _global)._elements[e].element.setStyle((this || _global).config.attribute, t[e]);
      }, e.clear = function () {
        var t,
          e = {};
        for (t in (this || _global)._values) (this || _global)._elements[t] && (e[t] = (this || _global)._elements[t].element.shape.style.initial[(this || _global).config.attribute]);
        this.setAttributes(e), (this || _global)._values = {};
      }, t;
    }();
  var E = {
    mill: function (t, e, i) {
      return {
        x: (this || _global).radius * (e - i) * (this || _global).radDeg,
        y: -(this || _global).radius * Math.log(Math.tan((45 + 0.4 * t) * (this || _global).radDeg)) / 0.8
      };
    },
    merc: function (t, e, i) {
      return {
        x: (this || _global).radius * (e - i) * (this || _global).radDeg,
        y: -(this || _global).radius * Math.log(Math.tan(Math.PI / 4 + t * Math.PI / 360))
      };
    },
    aea: function (t, e, i) {
      var s = i * (this || _global).radDeg,
        a = 29.5 * (this || _global).radDeg,
        n = 45.5 * (this || _global).radDeg,
        r = t * (this || _global).radDeg,
        o = e * (this || _global).radDeg,
        h = (Math.sin(a) + Math.sin(n)) / 2,
        l = Math.cos(a) * Math.cos(a) + 2 * h * Math.sin(a),
        c = h * (o - s),
        u = Math.sqrt(l - 2 * h * Math.sin(r)) / h,
        p = Math.sqrt(l - 2 * h * Math.sin(0)) / h;
      return {
        x: u * Math.sin(c) * (this || _global).radius,
        y: -(p - u * Math.cos(c)) * (this || _global).radius
      };
    },
    lcc: function (t, e, i) {
      var s = i * (this || _global).radDeg,
        a = e * (this || _global).radDeg,
        n = 33 * (this || _global).radDeg,
        r = 45 * (this || _global).radDeg,
        o = t * (this || _global).radDeg,
        h = Math.log(Math.cos(n) * (1 / Math.cos(r))) / Math.log(Math.tan(Math.PI / 4 + r / 2) * (1 / Math.tan(Math.PI / 4 + n / 2))),
        l = Math.cos(n) * Math.pow(Math.tan(Math.PI / 4 + n / 2), h) / h,
        c = l * Math.pow(1 / Math.tan(Math.PI / 4 + o / 2), h),
        u = l * Math.pow(1 / Math.tan(Math.PI / 4 + 0), h);
      return {
        x: c * Math.sin(h * (a - s)) * (this || _global).radius,
        y: -(u - c * Math.cos(h * (a - s))) * (this || _global).radius
      };
    }
  };
  E.degRad = 180 / Math.PI, E.radDeg = Math.PI / 180, E.radius = 6381372;
  var T = {
      _setupContainerEvents: function () {
        var t,
          e,
          i = this || _global,
          s = this || _global,
          a = !1;
        (this || _global).params.draggable && (S.on((this || _global).container, "mousemove", function (i) {
          if (!a) return !1;
          s.transX -= (t - i.pageX) / s.scale, s.transY -= (e - i.pageY) / s.scale, s._applyTransform(), t = i.pageX, e = i.pageY;
        }), S.on((this || _global).container, "mousedown", function (i) {
          return a = !0, t = i.pageX, e = i.pageY, !1;
        }), S.on(document.body, "mouseup", function () {
          a = !1;
        })), (this || _global).params.zoomOnScroll && S.on((this || _global).container, "wheel", function (t) {
          var e = 75 * ((t.deltaY || -t.wheelDelta || t.detail) >> 10 || 1),
            a = i.container.getBoundingClientRect(),
            n = t.pageX - a.left - window.pageXOffset,
            r = t.pageY - a.top - window.pageYOffset,
            o = Math.pow(1 + s.params.zoomOnScrollSpeed / 1000, -1.5 * e);
          s.tooltip && s._tooltip.hide(), s._setScale(s.scale * o, n, r), t.preventDefault();
        });
      },
      _setupElementEvents: function () {
        var t,
          e,
          i,
          s = this || _global,
          a = (this || _global).container;
        S.on(a, "mousemove", function (s) {
          Math.abs(t - s.pageX) + Math.abs(e - s.pageY) > 2 && (i = !0);
        }), S.delegate(a, "mousedown", ".jvm-element", function (s) {
          t = s.pageX, e = s.pageY, i = !1;
        }), S.delegate(a, "mouseover mouseout", ".jvm-element", function (t) {
          var e = M(s, this || _global, !0),
            i = s.params.showTooltip;
          "mouseover" === t.type ? (e.element.hover(!0), i && (s._tooltip.text(e.tooltipText), s._tooltip.show(), s._emit(e.event, [t, s._tooltip, e.code]))) : (e.element.hover(!1), i && s._tooltip.hide());
        }), S.delegate(a, "mouseup", ".jvm-element", function (t) {
          var e = M(s, this || _global);
          if (!i && ("region" === e.type && s.params.regionsSelectable || "marker" === e.type && s.params.markersSelectable)) {
            var a = e.element;
            s.params[e.type + "sSelectableOne"] && s._clearSelected(e.type + "s"), e.element.isSelected ? a.select(!1) : a.select(!0), s._emit(e.event, [e.code, a.isSelected, s._getSelected(e.type + "s")]);
          }
        }), S.delegate(a, "click", ".jvm-element", function (t) {
          var e = M(s, this || _global),
            i = e.type,
            a = e.code;
          s._emit("region" === i ? k.onRegionClick : k.onMarkerClick, [t, a]);
        });
      },
      _setupZoomButtons: function () {
        var t = this || _global,
          e = m("div", "jvm-zoom-btn jvm-zoomin", "&#43;", !0),
          i = m("div", "jvm-zoom-btn jvm-zoomout", "&#x2212", !0);
        (this || _global).container.appendChild(e), (this || _global).container.appendChild(i);
        var s = function (e) {
          return void 0 === e && (e = !0), function () {
            return t._setScale(e ? t.scale * t.params.zoomStep : t.scale / t.params.zoomStep, t._width / 2, t._height / 2, !1, t.params.zoomAnimate);
          };
        };
        S.on(e, "click", s()), S.on(i, "click", s(!1));
      },
      _setupContainerTouchEvents: function () {
        var t,
          e,
          i,
          s,
          a,
          n,
          r,
          o = this || _global,
          h = function (h) {
            var l,
              c,
              u,
              p,
              d = h.touches;
            if ("touchstart" == h.type && (r = 0), 1 == d.length) 1 == r && (u = o.transX, p = o.transY, o.transX -= (i - d[0].pageX) / o.scale, o.transY -= (s - d[0].pageY) / o.scale, o._tooltip.hide(), o._applyTransform(), u == o.transX && p == o.transY || h.preventDefault()), i = d[0].pageX, s = d[0].pageY;else if (2 == d.length) if (2 == r) c = Math.sqrt(Math.pow(d[0].pageX - d[1].pageX, 2) + Math.pow(d[0].pageY - d[1].pageY, 2)) / e, o._setScale(t * c, a, n), o._tooltip.hide(), h.preventDefault();else {
              var f = o.container.getBoundingClientRect();
              l = {
                top: f.top + window.scrollY,
                left: f.left + window.scrollX
              }, a = d[0].pageX > d[1].pageX ? d[1].pageX + (d[0].pageX - d[1].pageX) / 2 : d[0].pageX + (d[1].pageX - d[0].pageX) / 2, n = d[0].pageY > d[1].pageY ? d[1].pageY + (d[0].pageY - d[1].pageY) / 2 : d[0].pageY + (d[1].pageY - d[0].pageY) / 2, a -= l.left, n -= l.top, t = o.scale, e = Math.sqrt(Math.pow(d[0].pageX - d[1].pageX, 2) + Math.pow(d[0].pageY - d[1].pageY, 2));
            }
            r = d.length;
          };
        S.on(o.container, "touchstart", h), S.on(o.container, "touchmove", h);
      },
      _createRegions: function () {
        for (var t in (this || _global)._regionLabelsGroup = (this || _global)._regionLabelsGroup || (this || _global).canvas.createGroup("jvm-regions-labels-group"), (this || _global)._mapData.paths) {
          var e = new C({
            map: this || _global,
            code: t,
            path: (this || _global)._mapData.paths[t].path,
            style: v({}, (this || _global).params.regionStyle),
            labelStyle: (this || _global).params.regionLabelStyle,
            labelsGroup: (this || _global)._regionLabelsGroup,
            label: (this || _global).params.labels && (this || _global).params.labels.regions
          });
          (this || _global).regions[t] = {
            config: (this || _global)._mapData.paths[t],
            element: e
          };
        }
      },
      _createLines: function (t, e, i) {
        void 0 === i && (i = !1);
        var s = !1,
          a = !1;
        for (var n in (this || _global).linesGroup = (this || _global).linesGroup || (this || _global).canvas.createGroup("jvm-lines-group"), t) {
          var r = t[n];
          for (var o in e) {
            var h = i ? e[o].config : e[o];
            h.name === r.from && (s = this.getMarkerPosition(h)), h.name === r.to && (a = this.getMarkerPosition(h));
          }
          !1 !== s && !1 !== a && ((this || _global)._lines[_(r.from, r.to)] = new L({
            index: n,
            map: this || _global,
            style: v({
              initial: (this || _global).params.lineStyle
            }, {
              initial: r.style || {}
            }, !0),
            x1: s.x,
            y1: s.y,
            x2: a.x,
            y2: a.y,
            group: (this || _global).linesGroup,
            config: r
          }));
        }
      },
      _createMarkers: function (t, e) {
        var i = this || _global;
        void 0 === t && (t = {}), void 0 === e && (e = !1), (this || _global)._markersGroup = (this || _global)._markersGroup || (this || _global).canvas.createGroup("jvm-markers-group"), (this || _global)._markerLabelsGroup = (this || _global)._markerLabelsGroup || (this || _global).canvas.createGroup("jvm-markers-labels-group");
        var s = function () {
          var s = t[a],
            n = i.getMarkerPosition(s),
            r = s.coords.join(":");
          if (!n) return "continue";
          if (e) {
            if (Object.keys(i._markers).filter(function (t) {
              return i._markers[t]._uid === r;
            }).length) return "continue";
            a = Object.keys(i._markers).length;
          }
          var o = new O({
            index: a,
            map: i,
            style: v(i.params.markerStyle, {
              initial: s.style || {}
            }, !0),
            label: i.params.labels && i.params.labels.markers,
            labelsGroup: i._markerLabelsGroup,
            cx: n.x,
            cy: n.y,
            group: i._markersGroup,
            marker: s,
            isRecentlyCreated: e
          });
          i._markers[a] && i.removeMarkers([a]), i._markers[a] = {
            _uid: r,
            config: s,
            element: o
          };
        };
        for (var a in t) s();
      },
      _createSeries: function () {
        for (var t in (this || _global).series = {
          markers: [],
          regions: []
        }, (this || _global).params.series) for (var e = 0; e < (this || _global).params.series[t].length; e++) (this || _global).series[t][e] = new Y((this || _global).params.series[t][e], "markers" === t ? (this || _global)._markers : (this || _global).regions, this || _global);
      },
      _applyTransform: function () {
        var t, e, i, s;
        (this || _global)._defaultWidth * (this || _global).scale <= (this || _global)._width ? (t = ((this || _global)._width - (this || _global)._defaultWidth * (this || _global).scale) / (2 * (this || _global).scale), i = ((this || _global)._width - (this || _global)._defaultWidth * (this || _global).scale) / (2 * (this || _global).scale)) : (t = 0, i = ((this || _global)._width - (this || _global)._defaultWidth * (this || _global).scale) / (this || _global).scale), (this || _global)._defaultHeight * (this || _global).scale <= (this || _global)._height ? (e = ((this || _global)._height - (this || _global)._defaultHeight * (this || _global).scale) / (2 * (this || _global).scale), s = ((this || _global)._height - (this || _global)._defaultHeight * (this || _global).scale) / (2 * (this || _global).scale)) : (e = 0, s = ((this || _global)._height - (this || _global)._defaultHeight * (this || _global).scale) / (this || _global).scale), (this || _global).transY > e ? (this || _global).transY = e : (this || _global).transY < s && ((this || _global).transY = s), (this || _global).transX > t ? (this || _global).transX = t : (this || _global).transX < i && ((this || _global).transX = i), (this || _global).canvas.applyTransformParams((this || _global).scale, (this || _global).transX, (this || _global).transY), (this || _global)._markers && this._repositionMarkers(), (this || _global)._lines && this._repositionLines(), this._repositionLabels();
      },
      _resize: function () {
        var t = (this || _global)._baseScale;
        (this || _global)._width / (this || _global)._height > (this || _global)._defaultWidth / (this || _global)._defaultHeight ? ((this || _global)._baseScale = (this || _global)._height / (this || _global)._defaultHeight, (this || _global)._baseTransX = Math.abs((this || _global)._width - (this || _global)._defaultWidth * (this || _global)._baseScale) / (2 * (this || _global)._baseScale)) : ((this || _global)._baseScale = (this || _global)._width / (this || _global)._defaultWidth, (this || _global)._baseTransY = Math.abs((this || _global)._height - (this || _global)._defaultHeight * (this || _global)._baseScale) / (2 * (this || _global)._baseScale)), (this || _global).scale *= (this || _global)._baseScale / t, (this || _global).transX *= (this || _global)._baseScale / t, (this || _global).transY *= (this || _global)._baseScale / t;
      },
      _setScale: function (t, e, i, s, a) {
        var n,
          r,
          o,
          h,
          l,
          c,
          u,
          p,
          d,
          f,
          m = this || _global,
          g = 0,
          v = Math.abs(Math.round(60 * (t - (this || _global).scale) / Math.max(t, (this || _global).scale)));
        t > (this || _global).params.zoomMax * (this || _global)._baseScale ? t = (this || _global).params.zoomMax * (this || _global)._baseScale : t < (this || _global).params.zoomMin * (this || _global)._baseScale && (t = (this || _global).params.zoomMin * (this || _global)._baseScale), void 0 !== e && void 0 !== i && (n = t / (this || _global).scale, s ? (d = e + (this || _global)._defaultWidth * ((this || _global)._width / ((this || _global)._defaultWidth * t)) / 2, f = i + (this || _global)._defaultHeight * ((this || _global)._height / ((this || _global)._defaultHeight * t)) / 2) : (d = (this || _global).transX - (n - 1) / t * e, f = (this || _global).transY - (n - 1) / t * i)), a && v > 0 ? (o = (this || _global).scale, h = (t - o) / v, l = (this || _global).transX * (this || _global).scale, u = (this || _global).transY * (this || _global).scale, c = (d * t - l) / v, p = (f * t - u) / v, r = setInterval(function () {
          g += 1, m.scale = o + h * g, m.transX = (l + c * g) / m.scale, m.transY = (u + p * g) / m.scale, m._applyTransform(), g == v && (clearInterval(r), m._emit(k.onViewportChange, [m.scale, m.transX, m.transY]));
        }, 10)) : ((this || _global).transX = d, (this || _global).transY = f, (this || _global).scale = t, this._applyTransform(), this._emit(k.onViewportChange, [(this || _global).scale, (this || _global).transX, (this || _global).transY]));
      },
      setFocus: function (t) {
        var e = this || _global;
        void 0 === t && (t = {});
        var i,
          s = [];
        if (t.region ? s.push(t.region) : t.regions && (s = t.regions), s.length) return s.forEach(function (t) {
          if (e.regions[t]) {
            var s = e.regions[t].element.shape.getBBox();
            s && (i = void 0 === i ? s : {
              x: Math.min(i.x, s.x),
              y: Math.min(i.y, s.y),
              width: Math.max(i.x + i.width, s.x + s.width) - Math.min(i.x, s.x),
              height: Math.max(i.y + i.height, s.y + s.height) - Math.min(i.y, s.y)
            });
          }
        }), this._setScale(Math.min((this || _global)._width / i.width, (this || _global)._height / i.height), -(i.x + i.width / 2), -(i.y + i.height / 2), !0, t.animate);
        if (t.coords) {
          var a = this.coordsToPoint(t.coords[0], t.coords[1]),
            n = (this || _global).transX - a.x / (this || _global).scale,
            r = (this || _global).transY - a.y / (this || _global).scale;
          return this._setScale(t.scale * (this || _global)._baseScale, n, r, !0, t.animate);
        }
      },
      updateSize: function () {
        (this || _global)._width = (this || _global).container.offsetWidth, (this || _global)._height = (this || _global).container.offsetHeight, this._resize(), (this || _global).canvas.setSize((this || _global)._width, (this || _global)._height), this._applyTransform();
      },
      coordsToPoint: function (t, e) {
        var i = G.maps[(this || _global).params.map].projection,
          s = E[i.type](t, e, i.centralMeridian),
          a = s.x,
          n = s.y,
          r = this.getInsetForPoint(a, n);
        if (!r) return !1;
        var o = r.bbox;
        return a = (a - o[0].x) / (o[1].x - o[0].x) * r.width * (this || _global).scale, n = (n - o[0].y) / (o[1].y - o[0].y) * r.height * (this || _global).scale, {
          x: a + (this || _global).transX * (this || _global).scale + r.left * (this || _global).scale,
          y: n + (this || _global).transY * (this || _global).scale + r.top * (this || _global).scale
        };
      },
      getInsetForPoint: function (t, e) {
        for (var i = G.maps[(this || _global).params.map].insets, s = 0; s < i.length; s++) {
          var a = i[s].bbox,
            n = a[0],
            r = a[1];
          if (t > n.x && t < r.x && e > n.y && e < r.y) return i[s];
        }
      },
      getMarkerPosition: function (t) {
        var e = t.coords;
        return G.maps[(this || _global).params.map].projection ? (this || _global).coordsToPoint.apply(this || _global, e) : {
          x: e[0] * (this || _global).scale + (this || _global).transX * (this || _global).scale,
          y: e[1] * (this || _global).scale + (this || _global).transY * (this || _global).scale
        };
      },
      _repositionLines: function () {
        var t = !1,
          e = !1;
        for (var i in (this || _global)._lines) {
          for (var s in (this || _global)._markers) {
            var a = (this || _global)._markers[s];
            a.config.name === (this || _global)._lines[i].config.from && (t = this.getMarkerPosition(a.config)), a.config.name === (this || _global)._lines[i].config.to && (e = this.getMarkerPosition(a.config));
          }
          !1 !== t && !1 !== e && (this || _global)._lines[i].setStyle({
            x1: t.x,
            y1: t.y,
            x2: e.x,
            y2: e.y
          });
        }
      },
      _repositionMarkers: function () {
        for (var t in (this || _global)._markers) {
          var e = this.getMarkerPosition((this || _global)._markers[t].config);
          !1 !== e && (this || _global)._markers[t].element.setStyle({
            cx: e.x,
            cy: e.y
          });
        }
      },
      _repositionLabels: function () {
        var t = (this || _global).params.labels;
        if (t) {
          if (t.regions) for (var e in (this || _global).regions) (this || _global).regions[e].element.updateLabelPosition();
          if (t.markers) for (var i in (this || _global)._markers) (this || _global)._markers[i].element.updateLabelPosition();
        }
      }
    },
    z = function () {
      function t(t, e) {
        (this || _global).node = this._createElement(t), e && this.set(e);
      }
      var e = t.prototype;
      return e._createElement = function (t) {
        return document.createElementNS("http://www.w3.org/2000/svg", t);
      }, e.addClass = function (t) {
        (this || _global).node.setAttribute("class", t);
      }, e.getBBox = function () {
        return (this || _global).node.getBBox();
      }, e.set = function (t, e) {
        if ("object" == typeof t) for (var i in t) this.applyAttr(i, t[i]);else this.applyAttr(t, e);
      }, e.get = function (t) {
        return (this || _global).style.initial[t];
      }, e.applyAttr = function (t, e) {
        (this || _global).node.setAttribute(t.replace(/[\w]([A-Z])/g, function (t) {
          return t[0] + "-" + t[1];
        }).toLowerCase(), e);
      }, e.remove = function () {
        g((this || _global).node);
      }, t;
    }(),
    P = function (e) {
      function i(t, i, s) {
        var a;
        return void 0 === s && (s = {}), (a = e.call(this || _global, t, i) || this || _global).isHovered = !1, a.isSelected = !1, a.style = s, a.style.current = {}, a.updateStyle(), a;
      }
      t(i, e);
      var s = i.prototype;
      return s.setStyle = function (t, e) {
        var i;
        "object" == typeof t ? v((this || _global).style.current, t) : v((this || _global).style.current, ((i = {})[t] = e, i));
        this.updateStyle();
      }, s.updateStyle = function () {
        var t = {};
        v(t, (this || _global).style.initial), v(t, (this || _global).style.current), (this || _global).isHovered && v(t, (this || _global).style.hover), (this || _global).isSelected && (v(t, (this || _global).style.selected), (this || _global).isHovered && v(t, (this || _global).style.selectedHover)), this.set(t);
      }, i;
    }(z),
    D = function (e) {
      function i(t, i) {
        return e.call(this || _global, "text", t, i) || this || _global;
      }
      return t(i, e), i.prototype.applyAttr = function (t, i) {
        "text" === t ? (this || _global).node.textContent = i : e.prototype.applyAttr.call(this || _global, t, i);
      }, i;
    }(P),
    I = function (e) {
      function i(t, i) {
        return e.call(this || _global, "image", t, i) || this || _global;
      }
      return t(i, e), i.prototype.applyAttr = function (t, i) {
        var s;
        "image" === t ? ("object" == typeof i ? (s = i.url, (this || _global).offset = i.offset || [0, 0]) : (s = i, (this || _global).offset = [0, 0]), (this || _global).node.setAttributeNS("http://www.w3.org/1999/xlink", "href", s), (this || _global).width = 23, (this || _global).height = 23, this.applyAttr("width", (this || _global).width), this.applyAttr("height", (this || _global).height), this.applyAttr("x", (this || _global).cx - (this || _global).width / 2 + (this || _global).offset[0]), this.applyAttr("y", (this || _global).cy - (this || _global).height / 2 + (this || _global).offset[1])) : "cx" == t ? ((this || _global).cx = i, (this || _global).width && this.applyAttr("x", i - (this || _global).width / 2 + (this || _global).offset[0])) : "cy" == t ? ((this || _global).cy = i, (this || _global).height && this.applyAttr("y", i - (this || _global).height / 2 + (this || _global).offset[1])) : e.prototype.applyAttr.apply(this || _global, arguments);
      }, i;
    }(P),
    R = function (e) {
      function i(t) {
        var i;
        return (i = e.call(this || _global, "svg") || this || _global)._container = t, i._defsElement = new z("defs"), i._rootElement = new z("g", {
          id: "jvm-regions-group"
        }), i.node.appendChild(i._defsElement.node), i.node.appendChild(i._rootElement.node), i._container.appendChild(i.node), i;
      }
      t(i, e);
      var s = i.prototype;
      return s.setSize = function (t, e) {
        (this || _global).node.setAttribute("width", t), (this || _global).node.setAttribute("height", e);
      }, s.applyTransformParams = function (t, e, i) {
        (this || _global)._rootElement.node.setAttribute("transform", "scale(" + t + ") translate(" + e + ", " + i + ")");
      }, s.createPath = function (t, e) {
        var i = new P("path", t, e);
        return i.node.setAttribute("fill-rule", "evenodd"), this._add(i);
      }, s.createCircle = function (t, e, i) {
        var s = new P("circle", t, e);
        return this._add(s, i);
      }, s.createLine = function (t, e, i) {
        var s = new P("line", t, e);
        return this._add(s, i);
      }, s.createText = function (t, e, i) {
        var s = new D(t, e);
        return this._add(s, i);
      }, s.createImage = function (t, e, i) {
        var s = new I(t, e);
        return this._add(s, i);
      }, s.createGroup = function (t) {
        var e = new z("g");
        return (this || _global).node.appendChild(e.node), t && (e.node.id = t), e.canvas = this || _global, e;
      }, s._add = function (t, e) {
        return (e = e || (this || _global)._rootElement).node.appendChild(t.node), t;
      }, i;
    }(z),
    H = function (e) {
      function s(t) {
        var s;
        s = e.call(this || _global) || this || _global;
        var a = m("div", "jvm-tooltip");
        return s._map = t, s._tooltip = document.body.appendChild(a), s._bindEventListeners(), i(s) || i(s);
      }
      t(s, e);
      var a = s.prototype;
      return a._bindEventListeners = function () {
        var t = this || _global;
        S.on((this || _global)._map.container, "mousemove", function (e) {
          if (t._tooltip.classList.contains("active")) {
            var i,
              s,
              a = (i = t._map.container, s = "#jvm-regions-group", Element.prototype.querySelector.call(i, s)).getBoundingClientRect(),
              n = t._tooltip.getBoundingClientRect(),
              r = n.height,
              o = n.width,
              h = e.clientY <= a.top + r + 5,
              l = e.pageY - r - 5,
              c = e.pageX - o - 5;
            h && (l += r + 5, c -= 10), e.clientX < a.left + o + 5 && (c = e.pageX + 5 + 2, h && (c += 10)), t.css({
              top: l + "px",
              left: c + "px"
            });
          }
        });
      }, a.getElement = function () {
        return (this || _global)._tooltip;
      }, a.show = function () {
        (this || _global)._tooltip.classList.add("active");
      }, a.hide = function () {
        (this || _global)._tooltip.classList.remove("active");
      }, a.text = function (t, e) {
        void 0 === e && (e = !1);
        var i = e ? "innerHTML" : "textContent";
        if (!t) return (this || _global)._tooltip[i];
        (this || _global)._tooltip[i] = t;
      }, a.css = function (t) {
        for (var e in t) (this || _global)._tooltip.style[e] = t[e];
        return this || _global;
      }, s;
    }(x),
    V = function () {
      function t(t, e) {
        var i = t.scale,
          s = t.values;
        (this || _global)._scale = i, (this || _global)._values = s, (this || _global)._fromColor = this.hexToRgb(i[0]), (this || _global)._toColor = this.hexToRgb(i[1]), (this || _global)._map = e, this.setMinMaxValues(s), this.visualize();
      }
      var e = t.prototype;
      return e.setMinMaxValues = function (t) {
        for (var e in (this || _global).min = Number.MAX_VALUE, (this || _global).max = 0, t) (e = parseFloat(t[e])) > (this || _global).max && ((this || _global).max = e), e < (this || _global).min && ((this || _global).min = e);
      }, e.visualize = function () {
        var t,
          e = {};
        for (var i in (this || _global)._values) t = parseFloat((this || _global)._values[i]), isNaN(t) || (e[i] = this.getValue(t));
        this.setAttributes(e);
      }, e.setAttributes = function (t) {
        for (var e in t) (this || _global)._map.regions[e] && (this || _global)._map.regions[e].element.setStyle("fill", t[e]);
      }, e.getValue = function (t) {
        for (var e, i = "#", s = 0; s < 3; s++) i += (1 === (e = Math.round((this || _global)._fromColor[s] + ((this || _global)._toColor[s] - (this || _global)._fromColor[s]) * ((t - (this || _global).min) / ((this || _global).max - (this || _global).min))).toString(16)).length ? "0" : "") + e;
        return i;
      }, e.hexToRgb = function (t) {
        var e = 0,
          i = 0,
          s = 0;
        return 4 == t.length ? (e = "0x" + t[1] + t[1], i = "0x" + t[2] + t[2], s = "0x" + t[3] + t[3]) : 7 == t.length && (e = "0x" + t[1] + t[2], i = "0x" + t[3] + t[4], s = "0x" + t[5] + t[6]), [parseInt(e), parseInt(i), parseInt(s)];
      }, t;
    }(),
    G = function () {
      function t(e) {
        var i = this || _global;
        if (void 0 === e && (e = {}), (this || _global).params = v(t.defaults, e, !0), !t.maps[(this || _global).params.map]) throw new Error("Attempt to use map which was not loaded: " + e.map);
        (this || _global).regions = {}, (this || _global).scale = 1, (this || _global).transX = 0, (this || _global).transY = 0, (this || _global)._mapData = t.maps[(this || _global).params.map], (this || _global)._markers = {}, (this || _global)._lines = {}, (this || _global)._defaultWidth = (this || _global)._mapData.width, (this || _global)._defaultHeight = (this || _global)._mapData.height, (this || _global)._height = 0, (this || _global)._width = 0, (this || _global)._baseScale = 1, (this || _global)._baseTransX = 0, (this || _global)._baseTransY = 0, "loading" !== document.readyState ? this._init() : window.addEventListener("DOMContentLoaded", function () {
          return i._init();
        });
      }
      var e = t.prototype;
      return e._init = function () {
        var t = (this || _global).params;
        (this || _global).container = f(t.selector), (this || _global).container.classList.add("jvm-container"), (this || _global).canvas = new R((this || _global).container), this.setBackgroundColor(t.backgroundColor), this._createRegions(), this.updateSize(), this._createLines(t.lines || {}, t.markers || {}), this._createMarkers(t.markers), this._repositionLabels(), this._setupContainerEvents(), this._setupElementEvents(), t.zoomButtons && this._setupZoomButtons(), t.showTooltip && ((this || _global)._tooltip = new H(this || _global)), t.selectedRegions && this._setSelected("regions", t.selectedRegions), t.selectedMarkers && this._setSelected("_markers", t.selectedMarkers), t.focusOn && this.setFocus(t.focusOn), t.visualizeData && ((this || _global).dataVisualization = new V(t.visualizeData, this || _global)), t.bindTouchEvents && ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch) && this._setupContainerTouchEvents(), t.series && ((this || _global).container.appendChild((this || _global).legendHorizontal = m("div", "jvm-series-container jvm-series-h")), (this || _global).container.appendChild((this || _global).legendVertical = m("div", "jvm-series-container jvm-series-v")), this._createSeries()), this._emit(k.onLoaded, [this || _global]);
      }, e.setBackgroundColor = function (t) {
        (this || _global).container.style.backgroundColor = t;
      }, e.getSelectedRegions = function () {
        return this._getSelected("regions");
      }, e.clearSelectedRegions = function () {
        this._clearSelected("regions");
      }, e.getSelectedMarkers = function () {
        return this._getSelected("_markers");
      }, e.clearSelectedMarkers = function () {
        this._clearSelected("_markers");
      }, e.addMarkers = function (t) {
        if (Array.isArray(t)) return this._createMarkers(t, !0);
        this._createMarkers([t], !0);
      }, e.removeMarkers = function (t) {
        var e = this || _global;
        t || (t = Object.keys((this || _global)._markers)), t.forEach(function (t) {
          e._markers[t].element.remove(), delete e._markers[t];
        });
      }, e.addLine = function (t, e, i) {
        void 0 === i && (i = {}), console.warn("`addLine` method is deprecated, please use `addLines` instead."), this._createLines([{
          from: t,
          to: e,
          style: i
        }], (this || _global)._markers, !0);
      }, e.addLines = function (t) {
        var e = this._getLinesAsUids();
        Array.isArray(t) || (t = [t]), this._createLines(t.filter(function (t) {
          return !(e.indexOf(_(t.from, t.to)) > -1);
        }), (this || _global)._markers, !0);
      }, e.removeLines = function (t) {
        var e = this || _global;
        (t = Array.isArray(t) ? t.map(function (t) {
          return _(t.from, t.to);
        }) : this._getLinesAsUids()).forEach(function (t) {
          e._lines[t].dispose(), delete e._lines[t];
        });
      }, e.removeLine = function (t, e) {
        console.warn("`removeLine` method is deprecated, please use `removeLines` instead.");
        var i = _(t, e);
        (this || _global)._lines.hasOwnProperty(i) && ((this || _global)._lines[i].element.remove(), delete (this || _global)._lines[i]);
      }, e.reset = function () {
        for (var t in (this || _global).series) for (var e = 0; e < (this || _global).series[t].length; e++) (this || _global).series[t][e].clear();
        (this || _global).legendHorizontal && (g((this || _global).legendHorizontal), (this || _global).legendHorizontal = null), (this || _global).legendVertical && (g((this || _global).legendVertical), (this || _global).legendVertical = null), (this || _global).scale = (this || _global)._baseScale, (this || _global).transX = (this || _global)._baseTransX, (this || _global).transY = (this || _global)._baseTransY, this._applyTransform(), this.clearSelectedMarkers(), this.clearSelectedRegions(), this.removeMarkers();
      }, e.destroy = function (t) {
        var e = this || _global;
        void 0 === t && (t = !0), S.flush(), (this || _global)._tooltip.dispose(), this._emit(k.onDestroyed), t && Object.keys(this || _global).forEach(function (t) {
          try {
            delete e[t];
          } catch (t) {}
        });
      }, e.extend = function (e, i) {
        if ("function" == typeof (this || _global)[e]) throw new Error("The method [" + e + "] does already exist, please use another name.");
        t.prototype[e] = i;
      }, e._emit = function (t, e) {
        for (var i in k) k[i] === t && "function" == typeof (this || _global).params[i] && (this || _global).params[i].apply(this || _global, e);
      }, e._getSelected = function (t) {
        var e = [];
        for (var i in (this || _global)[t]) (this || _global)[t][i].element.isSelected && e.push(i);
        return e;
      }, e._setSelected = function (t, e) {
        var i = this || _global;
        e.forEach(function (e) {
          i[t][e] && i[t][e].element.select(!0);
        });
      }, e._clearSelected = function (t) {
        var e = this || _global;
        this._getSelected(t).forEach(function (i) {
          e[t][i].element.select(!1);
        });
      }, e._getLinesAsUids = function () {
        return Object.keys((this || _global)._lines);
      }, t;
    }();
  G.maps = {}, G.defaults = {
    map: "world",
    backgroundColor: "transparent",
    draggable: !0,
    zoomButtons: !0,
    zoomOnScroll: !0,
    zoomOnScrollSpeed: 3,
    zoomMax: 12,
    zoomMin: 1,
    zoomAnimate: !0,
    showTooltip: !0,
    zoomStep: 1.5,
    bindTouchEvents: !0,
    lineStyle: {
      stroke: "#808080",
      strokeWidth: 1,
      strokeLinecap: "round"
    },
    markersSelectable: !1,
    markersSelectableOne: !1,
    markerStyle: {
      initial: {
        r: 7,
        fill: "#374151",
        fillOpacity: 1,
        stroke: "#FFF",
        strokeWidth: 5,
        strokeOpacity: 0.5
      },
      hover: {
        fill: "#3cc0ff",
        cursor: "pointer"
      },
      selected: {
        fill: "blue"
      },
      selectedHover: {}
    },
    markerLabelStyle: {
      initial: {
        fontFamily: "Verdana",
        fontSize: 12,
        fontWeight: 500,
        cursor: "default",
        fill: "#374151"
      },
      hover: {
        cursor: "pointer"
      },
      selected: {},
      selectedHover: {}
    },
    regionsSelectable: !1,
    regionsSelectableOne: !1,
    regionStyle: {
      initial: {
        fill: "#dee2e8",
        fillOpacity: 1,
        stroke: "none",
        strokeWidth: 0
      },
      hover: {
        fillOpacity: 0.7,
        cursor: "pointer"
      },
      selected: {
        fill: "#9ca3af"
      },
      selectedHover: {}
    },
    regionLabelStyle: {
      initial: {
        fontFamily: "Verdana",
        fontSize: "12",
        fontWeight: "bold",
        cursor: "default",
        fill: "#35373e"
      },
      hover: {
        cursor: "pointer"
      }
    }
  }, Object.assign(G.prototype, T);
  var B = function () {
    function t(t) {
      if (void 0 === t && (t = {}), !t.selector) throw new Error("Selector is not given.");
      return new G(t);
    }
    return t.addMap = function (t, e) {
      G.maps[t] = e;
    }, t;
  }();
  return window.jsVectorMap = B;
});
export default exports;